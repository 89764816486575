import { useRouter } from "next/router";
import { useEffect } from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorFallback = ({ error }: FallbackProps) => {
  const router = useRouter();

  useEffect(() => {
    // Optionally log additional information before redirecting
    console.error("Redirecting to error page due to:", error);
    router.replace("/error");
  }, [router, error]);

  // Optional: Display a message or loader while redirecting
  return (
    <div style={{ padding: "2rem", textAlign: "center" }}>
      <p>Something went wrong. Redirecting...</p>
    </div>
  );
}