import useIsHomepage from "@/hooks/useIsHomepage";
import React, { useState } from "react";
export const HeaderContext = React.createContext();

export const HeaderContextProvider = ({ children }) => {
  const isHomePage = useIsHomepage();

  // Determine if the header is at the top of the page or pinned
  const [isTop, setIsTop] = useState(true);
  const [isPinned, setIsPinned] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isHeroScrolledPast, setIsHeroScrolledPast] = useState(true);
  const [showBorder, setShowBorder] = useState(false);
  const [hoveredTab, setHoveredTab] = useState(null);

  const value = {
    isTop,
    isPinned,
    isSearchVisible,
    isHeroScrolledPast,
    setIsTop,
    setIsPinned,
    setIsSearchVisible,
    setIsHeroScrolledPast,
    showBorder,
    setShowBorder,
    hoveredTab,
    setHoveredTab,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => React.useContext(HeaderContext);
