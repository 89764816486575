import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Spinner from "@/components/Spinner";

type SearchBarProps = {
  className?: string;
  placeholder?: string;
  theme?: "dark" | "light";
  showArrow?: boolean;
};

const SearchBar = ({
  className = "",
  placeholder = "Search for recipes, ingredients, etc...",
  theme = "dark",
  showArrow = false,
}: SearchBarProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const router = useRouter();

  // Listen for query parameter changes
  useEffect(() => {
    // Handle initial and full route changes
    const q = router.query.q as string;
    if (router.pathname === "/search") {
      setValue(q || "");
    } else if (q) {
      setValue(q);
    }

    // Handle shallow route changes
    const handleRouteChange = (url: string) => {
      const searchParams = new URLSearchParams(
        new URL(url, window.location.origin).search
      );
      const q = searchParams.get("q");
      setValue(q || "");
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.query.q, router.pathname]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (value.trim() && !isLoading) {
      setIsLoading(true);
      // Create searchParams from existing query parameters
      const searchParams = new URLSearchParams(
        router.asPath.split("?")[1] || ""
      );
      searchParams.set("q", value.trim());

      // reset page to 1
      searchParams.set("page", "1");

      const newPath = `/search?${searchParams.toString()}`;

      if (router.pathname === "/search") {
        await router.replace(newPath, undefined, { shallow: true });
      } else {
        await router.push(newPath);
      }

      setIsLoading(false);
    }
  };

  return (
    <div
      className={clsx(
        "relative w-full rounded-full border-0 bg-white",
        className
      )}
    >
      <div
        className={clsx(
          "w-component h-component absolute inset-y-0 left-0 z-10 flex items-center justify-center rounded-full text-zinc-500"
        )}
      >
        <MagnifyingGlassIcon className="h-5 w-5" />
      </div>
      <form onSubmit={handleSubmit} className="flex w-full">
        <input
          type="search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          className={clsx(
            "h-component flex flex-1 appearance-none rounded-full pl-9 font-body text-sm placeholder:text-zinc-500 lg:pl-10",

            {
              "bg-zinc-100": theme === "light",
            }
          )}
        />
      </form>
      {showArrow && (
        <button
          className={clsx(
            "w-component h-component absolute inset-y-0 right-0 z-10 flex items-center justify-center rounded-full text-zinc-500"
          )}
          onClick={handleSubmit}
        >
          {isLoading ? <Spinner /> : <ArrowRightIcon className="size-5" />}
        </button>
      )}
    </div>
  );
};

export default SearchBar;
