import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const Breadcrumbs = ({ items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  const breadcrumbs = [{ label: "Home", href: "/" }, ...items];

  const MAX_TEXT_LENGTH_MOBILE = 11;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}…` : text;
  };

  return (
    <Breadcrumb className="lg:pb-2">
      <BreadcrumbList>
        {breadcrumbs.map((item, index) => {
          const isLast = index === breadcrumbs.length - 1;

          return (
            <>
              <BreadcrumbItem key={index}>
                {isLast ? (
                  <BreadcrumbPage isCurrentPage>
                    <span className="block sm:hidden">
                      {truncateText(item.label, MAX_TEXT_LENGTH_MOBILE)}
                    </span>
                    <span className="hidden sm:block">{item.label}</span>
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink href={item.href}>
                    <span className="block sm:hidden">
                      {truncateText(item.label, MAX_TEXT_LENGTH_MOBILE)}
                    </span>
                    <span className="hidden sm:block">{item.label}</span>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
            </>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
