import clsx from "clsx";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Form from "components/Form";
import TextField from "components/TextField";
import { Field, Formik } from "formik";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import { useMountedState } from "react-use";
import * as Yup from "yup";
import posthog from "posthog-js";

const Login = ({ darkMode = false }) => {
  const isMounted = useMountedState();

  const { authStore } = useStores();

  const validationSchema = Yup.object().shape({
    loginName: Yup.string()
      .email("Please enter a valid email address")
      .required("Your email address is required"),
    password: Yup.string().required("Please enter a password"),
  });

  const handleLogin = async (values, { setSubmitting, setFieldError }) => {
    try {
      const loginStatus = await authStore.login(values);

      if (loginStatus.success) {
      }

      if (!loginStatus.success) {
        setFieldError("loginName", loginStatus.error);
      }
    } catch (error) {
      if (isMounted) {
        // If there was a problem logging in, set the error on the form
        setFieldError("loginName", error);
        setSubmitting(false);
      }
    }
  };

  const RememberMeCheckbox = ({ field, form, ...props }) => (
    <div className={clsx("CheckboxField", "")}>
      <label className={clsx("CheckboxField__label", "flex items-center")}>
        <div
          className={clsx(
            "CheckboxField__labelLabel",
            "order-2 pl-3 font-body text-sm"
          )}
        >
          {props.label}
        </div>
        <Checkbox {...field} darkMode={darkMode} />
      </label>
    </div>
  );

  if (!isMounted) return null;

  return (
    <Formik
      initialValues={{
        loginName: "",
        password: "",
        rememberMe: false,
      }}
      validateOnBlur={false}
      onSubmit={handleLogin}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleReset, handleSubmit } = formikProps;

        return (
          <Form
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            attributes={{"aria-label": "Login Form"}}
            actions={[
              <Button
                key="submit"
                label={isSubmitting ? "Logging in..." : "Log In"}
                type="submit"
                isDisabled={isSubmitting}
                isFull
                theme="dark"
              />,
            ]}
          >
            <TextField
              name="loginName"
              label="Email"
              placeholder="Enter your email"
              type="email"
              value={values.loginName}
              // focusOnMount
            />
            <TextField
              name="password"
              label="Password"
              placeholder="Enter your password"
              type="password"
              value={values.password}
            />

            <Field
              type="checkbox"
              name="rememberMe"
              component={RememberMeCheckbox}
              label="Remember me"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

Login.displayName = "Login";

export default observer(Login);
